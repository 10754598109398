export default {
  name: 'AccordionContent',
  props: {
    accordionTitle: String,
    accordionContent: {
      type: String,
      default: ''
    },
    idAccordion: {
      type: Number,
      require: true
    },
    openAccordionState: {
      require: true
    }
  },
  methods: {
    openAccordion() {
      // Send current status of accordion to parent page
      const value = this.idAccordion === this.openAccordionState
        ? false
        : this.idAccordion;
      // Emit open accordion state change
      this.$emit('openAccordion', value);
    }
  }
}
