import store from "@/store"

export default {
  install(Vue) {
    Vue.prototype.$helpers = {
      // Format object to redirect with UTM query
      redirectWithUtmQuery: (name, query = {}, params = {}, replace = false) => {
        let returnObject = { name, replace };
      
        // Check if there are existing query params or UTM data to add
        const { utm_campaign, utm_medium, utm_source } = store.getters.utmData;
        const hasUtmData = utm_campaign || utm_medium || utm_source;
        
        // Add query params if they exist
        if (Object.keys(query).length > 0 || hasUtmData) {
          const newQuery = { ...query };
          
          // Add UTM data if available
          if (utm_campaign) newQuery.utm_campaign = utm_campaign;
          if (utm_medium) newQuery.utm_medium = utm_medium;
          if (utm_source) newQuery.utm_source = utm_source;
          
          returnObject.query = newQuery;
        }
        
        // Add params if they exist
        if (Object.keys(params).length > 0) {
          returnObject.params = params;
        }
        
        return returnObject;
      },
      // Convert hex color to rgba color
      hexToRgba: (hex, opacity) => {
        // If opacity is not provided, default to 1
        opacity = opacity ? (opacity / 100) : 1;

        // Remove the '#' character if present
        hex = hex.replace('#', '');
        
        // Parse the hexadecimal values for red, green, and blue
        var r = parseInt(hex.substring(0, 2), 16);
        var g = parseInt(hex.substring(2, 4), 16);
        var b = parseInt(hex.substring(4, 6), 16);
        
        // Create the RGBA string with the provided opacity
        var rgba = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
        
        return rgba;
      },
      // Get file extension (ex: jpg, png, mp4, etc.)
      getExtension(filePath) {
        return (
          filePath?.substring(filePath.lastIndexOf('.') + 1, filePath.length) ||
          filePath
        );
      },
      // Set style for root element
      setRootElementStyle(property, value) {
        // Get document element
        const root = document.documentElement;

        root.style.setProperty(property, value);
      },
      // Add plus sign phone number by adding + if it doesn't have it
      addPlusSignToPhoneNumber(phoneNumber) {
        if (phoneNumber.charAt(0) !== '+') {
          return `+${phoneNumber}`;
        }

        return phoneNumber;
      }
    };
  }
};
