import { mapGetters, mapState } from 'vuex';
import { IMAGES } from '@assets';
import {
  CAMPAIGN_SERIAL_TYPE,
  CAMPAIGN_PHONE_NUMBER_TYPE,
  ROUTER_MAPS,
  ROUTER_NAME_CAMPAIGN_HOME
} from '@constants';

export default {
  name: "AppHeader",
  computed: {
    ...mapGetters({
      headerLogoUrl: 'headerLogoUrl',
    }),
    ...mapState({
      campaignUrlName: 'campaignUrlName',
      campaignUrl: 'campaignUrl',
      campaignSetting: 'campaignSetting',
      campaignInfo: 'campaignInfo'
    }),
  },
  methods: {
    // Redirect to top page
    redirectToTopPage() {
      if (this.campaignUrl && this.campaignUrlName) {
        let isSkipTopFlag = this.campaignSetting.skip_top_flg ?? false;
        let campaignType = this.campaignInfo?.campaign_type;

        let routerName = ROUTER_NAME_CAMPAIGN_HOME;

        if (isSkipTopFlag && campaignType == CAMPAIGN_SERIAL_TYPE) {
          routerName = ROUTER_MAPS[CAMPAIGN_SERIAL_TYPE];
        } else if (isSkipTopFlag && campaignType == CAMPAIGN_PHONE_NUMBER_TYPE) {
          routerName = ROUTER_MAPS[CAMPAIGN_PHONE_NUMBER_TYPE];
        }

        // Check current route is the same route with routerName ==> reload page
        if (this.$router.currentRoute.name === routerName) {
          return this.$router.go(0);
        } else {
          this.$store.dispatch('startPageRedirectLoading');
          return this.$router.push({
              name: routerName,
              params: {
                campaignUrlName: this.campaignUrlName,
                campaignUrl: this.campaignUrl,
              },
              replace: true,
            })
            .then(() => {
              this.$store.dispatch('stopPageRedirectLoading');
            })
            .catch(() => {});
        }
      }

      return;
    },
    // Get header logo url
    fullHeaderLogoUrl() {
      return this.headerLogoUrl ? this.headerLogoUrl : IMAGES.giftee_logo;
    }
  },
}
