import {
  HTTP_CODE_OK,
  SURVEY_TOOL_SURVEY_MONKEY,
  SURVEY_TOOL_GOOGLE_FORM
} from '@constants'
import store from '@/store'

export default async function checkLotteryCampaign({ to, next, router }) {
  try {
    const state = store.state;

    // Check if have lottery campaign setting data will fetch data
    if (!state.campaignSetting && !state.loseContentSetting) {
      // [STORE] dispatch load lottery campaign setting
      const result = await store.dispatch('loadLotteryCampaignSetting', String(to.params.lotteryUuid));
      const resultData = result.data;

      if (result && result?.status && result?.data) {
        // Check survey tool is Survey monkey or google forms
        if (resultData?.survey_tool) {
          switch (resultData.survey_tool) {
            case SURVEY_TOOL_SURVEY_MONKEY: { // Survey monkey
              // Redirect to survey page
              if (resultData?.survey_link) {
                // Start page loading
                store.dispatch('startPageRedirectLoading');
      
                // Redirect to survey link
                window.location.href = resultData.survey_link;
              }

              break;
            }
            case SURVEY_TOOL_GOOGLE_FORM: { // Google form
              // Check is step survey will redirect to survey page
              if (resultData?.survey_link && resultData?.survey_key) {
                // Start page loading
                store.dispatch('startPageRedirectLoading');

                // Redirect to survey page
                return await router.push({
                  name: 'campaign.survey',
                  params: {
                    survey_link: resultData.survey_link,
                    survey_key: resultData.survey_key
                  },
                  replace: true
                }).catch(() => {});
              }

              break;
            }
          }
        }

        // Status is not 200 -> show error
        if (result.status !== HTTP_CODE_OK) {
          return await next({
            name: 'campaign.error.404'
          });
        }
      }
    }

    // Next guard router
    return await next();
  } catch (error) {
    return await next({
      name: 'campaign.error'
    });
  }
}
