export default {
  name: "ButtonDefault",
  props: {
    buttonText: {
      type: String,
      default: 'Submit',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    }
  },
}
