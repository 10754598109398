import store from '@/store'

export default function followUtmQuery ({ to, next }) {
    const utmCampaign = to.query?.utm_campaign ? String (to.query.utm_campaign) : ''
    const utmMedium = to.query?.utm_medium ? String (to.query.utm_medium) : ''
    const utmSource = to.query?.utm_source ? String (to.query.utm_source) : ''

    store.dispatch('setUtmData', {
        utmCampaign,
        utmMedium,
        utmSource
    });

    return next();
}
