import BaseService from './BaseService'

class CampaignService extends BaseService {
  async index(campaign_url_name, campaign_url, params = {}) {
    const res = await this.get(`/${campaign_url_name}/${campaign_url}`, { include: params.include })

    return res.data
  }

  // Get lottery campaign setting
  async lotteryCampaignSetting(lottery_uuid) {
    const res = await this.get(`/lottery/${lottery_uuid}/setting`);

    return res.data;
  }

  async auth(type, params = {}) {
    const res = await this.post(`/${type}`, params);

    return res.data;
  }

  async lotteryPlay(params = {}) {
    const res = await this.post('/lottery-play', params)

    return res.data
  }

  async createGiftUrl(params = {}) {
    const res = await this.post('/create-gift-url', params)

    return res.data
  }

  async lotteryPlayRedirect(params = {}) {
    const res = await this.post('/play-lottery-redirect', params)

    return res.data
  }

  async lotteryPlayRedemption(params = {}) {
    const res = await this.post('/play-lottery-redemption', params)

    return res.data
  }
}

export default new CampaignService()
