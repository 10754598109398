import { mapState } from 'vuex';
import { FOOTER_TEXT } from '@constants';

export default {
  name: "AppFooter",
  computed: {
    ...mapState({
      campaignSetting: 'campaignSetting',
    }),
  },
  methods: {
    // Get footer text
    footerContent() {
      return this.campaignSetting?.footer_text || FOOTER_TEXT;
    },
  },
}
