import { mapState } from 'vuex';
import {
  MainVisualContent,
  DetailContent,
  AccordionContent,
  ButtonDefault
} from "@components";

export default {
  name: 'PageContentReceive',
  props: {
    itemBrandName: String,
    itemName: String,
    winItemImageUrl: String,
    isCallingReceiveGift: Boolean,
    winItemMainText: String,
    winItemSubText: String
  },
  components: {
    MainVisualContent,
    DetailContent,
    AccordionContent,
    ButtonDefault
  },
  data() {
    return {
      openAccordionState: 0,
    }
  },
  computed: {
    ...mapState({
      receiveContentSetting: 'receiveContentSetting'
    })
  },
  methods: {
    // Get header logo url
    fullMainVisualUrl() {
      const mainVisualImageUrl = this.receiveContentSetting?.main_visual_image_url || this.winItemImageUrl;

      return mainVisualImageUrl ? mainVisualImageUrl : '';
    },
    // Set Open/Close state a collapse in a accordion
    openAccordion(value) {
      // Update open state of accordion
      this.openAccordionState = value
    },
    receiveGift() {
      this.$emit('handle-receive-gift');
    }
  },
}
