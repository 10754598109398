const IMAGES = {
    image_loading: require("@assets/img/image_loading.svg"),
    image_thumbnail_error: require("@assets/img/image_thumbnail_error.png"),
    logo_gift_card: require("@assets/img/header-logos/logo_gift_card.svg"),
    logo_languages: require("@assets/img/header-logos/logo_languages.svg"),
    giftee_logo: require("@assets/img/header-logos/giftee_logo.png"),
    down_icon: require("@assets/img/down_icon.svg"),
    gift_point_icon: require("@assets/img/gift_point_icon.svg"),
    gift_icon: require("@assets/img/gift_icon.svg"),
    gift_icon_active: require("@assets/img/gift_icon_active.svg"),
    my_gift_icon: require("@assets/img/my_gift_icon.svg"),
    my_gift_icon_active: require("@assets/img/my_gift_icon_active.svg"),
    close_icon: require("@assets/img/close_icon.svg"),
    selected_icon: require("@assets/img/selected_icon.svg"),
    clock_icon: require("@assets/img/clock_icon.svg"),
    box_gift_icon: require("@assets/img/box_gift_icon.svg"),
    back_icon: require("@assets/img/back_icon.svg"),
    error_404: require("@assets/img/errors/error_404.png"),
    error_500: require("@assets/img/errors/error_500.png"),
    empty_list: require("@assets/img/products/empty_list.png"),
    gift_card_item_flower: require("@assets/img/gift_card_item_flower.svg"),
    main_visual: {
        top: require("@assets/img/mainvisual_default.png"),
        lose: require("@assets/img/lose_default.png"),
    }
};

export {
    IMAGES,
}
