import Vue from 'vue';
import VueRouter from 'vue-router';
import middlewarePipeline from './middlewarePipeline';

Vue.use(VueRouter)

// Routes
import { routes } from './routes'

// Init router
const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      to,
      next,
      router,
    };

    return await middleware[0]({
      ...context,
      next: await middlewarePipeline(context, middleware, 1)
    })
  }

  return next();
});

export default router
