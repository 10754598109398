import {
  SERIAL_CODE_DEFAULT
} from '@constants';

export default {
  name: 'EntrySerialCode',
  props: {
    serialCodeInvalid: Boolean,
    messageErrors: String,
  },
  data() {
    return {
      inputSerialCode: '',
      maskSerialCode: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
    }
  },
  watch: {
    // [Emit] set input serial code value
    'inputSerialCode' (value) {
      // Set input serial code
      this.$emit('set-input-serial-code', value);
    }
  },
  created() {
    // Set input serial code when query have
    this.inputSerialCode = this.$route?.query?.code !== SERIAL_CODE_DEFAULT ? this.$route.query.code : '';
  }
}
