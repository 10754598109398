import Vue from 'vue'
import App from './App.vue'

// ====== Route
import router from './router'

// ====== BootstrapVue
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

// App styles
import "@assets/scss/styles.scss";

// ====== Input Mask
import VueMask from 'di-vue-mask'
Vue.use(VueMask)

// ====== Locale
import i18n from '@i18n'

// ====== Store (Vuex)
import store from '@/store';

Vue.directive('uppercase', {
  update: function(el) {
    el.value = el.value.toUpperCase()
  }
})

// ====== Utils
import helpers from '@/utils/helpers';
Vue.use(helpers)

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
