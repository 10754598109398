import { IMAGES } from '@assets';
import { mapState } from 'vuex';
import {
  MainVisualContent,
  DetailContent,
  AccordionContent,
  ButtonDefault
} from "@components";

export default {
  name: 'PageContentLose',
  components: {
    MainVisualContent,
    DetailContent,
    AccordionContent,
    ButtonDefault
  },
  data() {
    return {
      openAccordionState: 0,
      defaultImageUrl: IMAGES.main_visual.lose
    }
  },
  computed: {
    ...mapState({
      loseContentSetting: 'loseContentSetting',
      campaignUrl: 'campaignUrl',
      campaignUrlName: 'campaignUrlName',
    })
  },
  methods: {
    // Get header logo url
    fullMainVisualUrl() {
      return this.loseContentSetting?.main_visual_image_url || this.defaultImageUrl;
    },
    // Set Open/Close state a collapse in a accordion
    openAccordion(value) {
      // Update open state of accordion
      this.openAccordionState = value
    },
    backToTop() {
      this.campaignUrl
        ? window.location.href = `/${this.campaignUrlName}/${this.campaignUrl}`
        : {}
    }
  },
}
