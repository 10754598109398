import BaseService from './BaseService'
import { signInWithPhoneNumber, AuthErrorCodes, signOut } from 'firebase/auth';
import { auth as FireBaseAuth } from '@firebaseConfig';
import {
  OTP_CHANNEL_SMS,
  OTP_CHANNEL_ZALO,
  API_STATUS_CODE_VERIFY_PHONE_NUMBER_SUCCESS,
  API_STATUS_CODE_PHONE_NUMBER_NOT_IN_WHITE_LIST,
  API_STATUS_CODE_SEND_OTP_SUCCESS,
  API_STATUS_CODE_SEND_OTP_VIA_SNS_FAILED,
  API_STATUS_CODE_INVALID_OTP_CHANNEL,
  API_STATUS_CODE_VERIFY_OTP_SUCCESS,
  API_STATUS_CODE_CAMPAIGN_NOT_EXIST,
  API_STATUS_CODE_PHONE_NUMBER_VALIDATION_FAILED,
  API_STATUS_CODE_VERIFY_OTP_FAILED,
  API_STATUS_CODE_RATE_LIMIT_EXCEED,
  HTTP_CODE_BAD_REQUEST,
  API_STATUS_CODE_IS_EXPIRED
} from '@constants'
import router from '@router'
import Vue from 'vue';

// Base path
const basePath = '/otp';

class OtpAuthService extends BaseService {
  /**
   * Verify by phone number
   *
   * @param {object} payload
   * @return object
   */
  async verifyPhoneNumber(payload = {}) {
    // Call verify phone number API
    const result = await this.post(`${basePath}/verify-phone-number`, payload);

    switch (result?.data?.status) {
      case API_STATUS_CODE_VERIFY_PHONE_NUMBER_SUCCESS:
        return this.handleResponse(true, '', result?.data?.data);
      case API_STATUS_CODE_PHONE_NUMBER_NOT_IN_WHITE_LIST:
        return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.NOT_IN_WHITELIST');
      case HTTP_CODE_BAD_REQUEST: {
        const resultData = result?.data?.data;

        if (resultData?.status == API_STATUS_CODE_IS_EXPIRED) { // Case campaign is expired
          return router.push(
            Vue.prototype.$helpers.redirectWithUtmQuery(
              'campaign.home',
              {},
              {
                campaignUrlName: resultData.data.campaign_url_name,
                campaignUrl: resultData.data.campaign_url,
              },
              true,
            ));
        } else if (resultData?.status == API_STATUS_CODE_RATE_LIMIT_EXCEED) { // Case rate limit exceeded
          return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.RATE_LIMIT_EXCEED');
        } else {
          return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.SOMETHING_WRONG');
        }
      }
      default:
        return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.SOMETHING_WRONG');
    }
  }

  /**
   * Send Otp to Phone number
   *
   * @param {object} payload
   * @return object
   */
  async sendOtp(payload = {}) {
    switch (payload.channel_id) {
      case OTP_CHANNEL_SMS:
        // Check is Resend OTP will resend OTP
        if (payload.resend_otp) {
          return await this.handleResendOtpChannelSMS(payload.full_phone_number, window.recaptchaVerifier);
        } else {
          // Call signInWithPhoneNumber from Firebase
          return await this.signInWithPhoneNumber(payload.full_phone_number, window.recaptchaVerifier);
        }
      case OTP_CHANNEL_ZALO:
        // Call send Otp to phone number
        return await this.handleSendOtpChannelZalo(payload);
      default:
        return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.INVALID_OTP_CHANNEL');
    }
  }

  /**
   * Verify Otp to Phone number
   *
   * @param {object} payload
   * @param {string} otpCode
   * @return object
   */
  async verifyOtp(payload = {}, otpCode) {
    // Check channel id
    switch (payload.channel_id) {
      case OTP_CHANNEL_SMS: {
        // Call verify otp code from firebase
        const resultVerify = await this.verifyOtpFromFirebase(otpCode);

        if (resultVerify.success) {
          payload.access_token = FireBaseAuth.currentUser.accessToken;
        } else {
          return resultVerify;
        }

        break;
      }
      case OTP_CHANNEL_ZALO:
        payload.otp = otpCode;

        break;
      default:
        return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.INVALID_OTP_CHANNEL');
    }

    // Call verify Otp API
    const result = await this.post(`${basePath}/verify`, payload);

    switch (result.data.status) {
      case API_STATUS_CODE_VERIFY_OTP_SUCCESS:
        return this.handleResponse(true, '', result.data.data);
      case API_STATUS_CODE_CAMPAIGN_NOT_EXIST:
        return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.SOMETHING_WRONG');
      case API_STATUS_CODE_PHONE_NUMBER_VALIDATION_FAILED:
        return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.PHONE_NUMBER_VALIDATION_FAILED');
      case API_STATUS_CODE_VERIFY_OTP_FAILED:
        return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.VERIFY_OTP_FAILED');
      case API_STATUS_CODE_INVALID_OTP_CHANNEL:
        return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.INVALID_OTP_CHANNEL');
      case HTTP_CODE_BAD_REQUEST: {
        const resultData = result?.data?.data;

        if (resultData?.status == API_STATUS_CODE_IS_EXPIRED) { // Case campaign is expired
          return router.push(
            Vue.prototype.$helpers.redirectWithUtmQuery(
              'campaign.home',
              {},
              {
                campaignUrlName: resultData.data.campaign_url_name,
                campaignUrl: resultData.data.campaign_url,
              },
              true,
            ));
        } else if (resultData?.status == API_STATUS_CODE_RATE_LIMIT_EXCEED) { // Case rate limit exceeded
          return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.RATE_LIMIT_EXCEED');
        } else {
          return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.SOMETHING_WRONG');
        }
      }
      default:
        return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.SOMETHING_WRONG');
    }
  }

  /**
   * Resend OTP code to phone number
   *
   * @param {object} payload
   * @return object
   */
  async resendOTP(payload = {}) {
    return await this.sendOTP(payload);
  }

  /**
   * Login with firebase
   *
   * @param {string} sendPhone
   * @param {*} appVerifier
   * @return object
   */
  async signInWithPhoneNumber(sendPhone, appVerifier) {
    return await signInWithPhoneNumber(FireBaseAuth, sendPhone, appVerifier)
    .then((confirmationResult) => {
      // Handle success: SMS sent
      window.confirmationResult = confirmationResult;

      window.recaptchaVerifier.render().then(function (widgetId) {
        window.grecaptcha.reset(widgetId);
      });

      localStorage.setItem('SEND_SMS_AT', new Date().getTime());

      return this.handleResponse(true);
    })
    .catch((e) => {
      // Handle errors
      switch (e.code) {
        case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
          return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.TOO_MANY_TIME_PLEASE_TRY_AGAIN_LATER');
        case AuthErrorCodes.QUOTA_EXCEEDED:
          return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.QUOTA_EXCEEDED');
        default:
          return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.SOMETHING_WRONG');
      }
    });
  }

  /**
   * Handle Resend OTP code channel SMS
   *
   * @param {string} phoneNumber
   * @param {*} appVerifier
   * @return string
   */
  async handleResendOtpChannelSMS(phoneNumber, appVerifier) {
    try {
      // Sign out from Firebase
      await signOut(FireBaseAuth);
  
      // After signing out, resend OTP with signInWithPhoneNumber
      const result = await this.signInWithPhoneNumber(phoneNumber, appVerifier);
  
      return result;
    } catch (error) {
      return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.SOMETHING_WRONG');
    }
  }

  /**
   * Handle send Otp channel Zalo
   *
   * @param {object} payload
   * @return object
   */
  async handleSendOtpChannelZalo(payload = {}) {
    // Call send Otp API
    const result = await this.post(`${basePath}/send`, payload);

    switch (result.data.status) {
      case API_STATUS_CODE_SEND_OTP_SUCCESS:
        return this.handleResponse(true);
      case API_STATUS_CODE_SEND_OTP_VIA_SNS_FAILED:
        return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.SEND_OTP_VIA_SNS_FAILED');
      case API_STATUS_CODE_INVALID_OTP_CHANNEL:
        return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.INVALID_OTP_CHANNEL');
      case HTTP_CODE_BAD_REQUEST: {
        const resultData = result?.data?.data;

        if (resultData?.status == API_STATUS_CODE_IS_EXPIRED) { // Case campaign is expired
          return router.push(
            Vue.prototype.$helpers.redirectWithUtmQuery(
              'campaign.home',
              {},
              {
                campaignUrlName: resultData.data.campaign_url_name,
                campaignUrl: resultData.data.campaign_url,
              },
              true,
            ));
        } else if (resultData?.status == API_STATUS_CODE_RATE_LIMIT_EXCEED) { // Case rate limit exceeded
          return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.RATE_LIMIT_EXCEED');
        } else {
          return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.SOMETHING_WRONG');
        }
      }
      default:
        return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.SOMETHING_WRONG');
    }
  }

  /**
   * Verify Otp from firebase
   *
   * @param {string} otpCode
   * @return object
   */
  async verifyOtpFromFirebase(otpCode) {
    try {
      await window.confirmationResult.confirm(otpCode);

      return this.handleResponse(true);
    } catch (e) {
      switch (e.code) {
        case AuthErrorCodes.INVALID_CODE:
          return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.VERIFICATION_CODE_INCORRECT');
        case AuthErrorCodes.CODE_EXPIRED:
          return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.CODE_EXPIRED');
        default:
          return this.handleResponse(false, 'VERIFY_OTP_PHONE_NUMBER_ERROR_MSG.SOMETHING_WRONG'); 
      }
    }
  }

  /**
   * Handle response
   *
   * @param {boolean} success
   * @param {string} errorMessage
   * @param {object|null} data
   * @return object
   */
  handleResponse(success, errorMessage = '', data = null) {
    const response = { success };

    // Check have error message 
    if (errorMessage) {
      response.error_message = errorMessage;
    }

    // Check have data
    if (data && typeof data === 'object' && !Array.isArray(data)) {
      response.data = data;
    }

    return response;
  }
}

export default new OtpAuthService()
