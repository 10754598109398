import BaseService from './BaseService'

class SurveyService extends BaseService {
  // Get survey status
  async getSurveyStatus(params = {}) {
    const res = await this.get('/survey/status', { key: params.key });

    return res.data;
  }

  // Handle survey callback
  async surveyCallback(params = {}) {
    const res = await this.get('/questionnaires/callback', { key: params.key })

    return res.data;
  }
}

export default new SurveyService()
