<template>
  <div id="app">
    <!-- Screen loading -->
    <ScreenLoading v-if="isPageRedirectLoading" transition="fade" />
    <!-- User custom styles -->
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  ScreenLoading
} from '@components';

export default {
  name: 'App',
  components: {
    ScreenLoading,
  },
  computed: {
    ...mapState({
      isPageRedirectLoading: 'isPageRedirectLoading',
    })
  }
}
</script>
