import { ANIMATION_TYPE_DEFAULT } from '@constants';

export default {
  name: 'LotteryAnimation',
  data() {
    return {
      animationDefault: ANIMATION_TYPE_DEFAULT
    };
  },
  props: {
    campaignSetting: {
      type: Object,
      required: true
    }
  },
  mounted() {
    // Assuming the API response is an object with CSS properties
    const root = document.documentElement;

    root.style.setProperty('--window-vw', window.innerWidth + 'px');
  },
}
