import { campaignService } from '@services';
import {
  HTTP_CODE_OK,
  FLOW_TYPE_REDIRECT,
  CAMPAIGN_SERIAL_TYPE,
  CAMPAIGN_PHONE_NUMBER_TYPE,
  WIN_FLAG,
  LOSE_FLAG,
  API_STATUS_CODE_RATE_LIMIT_EXCEED,
  APP_LANG_DEFAULT,
  FLOW_TYPE_REDEMPTION,
  ROUTER_MAPS
} from '@constants';
import { LANGUAGES } from '@i18n';
import store from '@/store';

// Logic process for campaign have type is redirect
// Play lottery and redirect to result page right away
const processCampaignFlowTypeRedirect = async (
  { to, router },
  { campaignUrlName, campaignUrl, campaignInfo }
) => {
  // Check campaign type must be serial
  if (campaignInfo.campaign_type !== CAMPAIGN_SERIAL_TYPE) {
    return await router.push({
      name: 'campaign.error.404',
      replace: true
    });
  }

  // Check url has serial code
  const regex = /\/[0-9a-zA-Z_-]+\/\w+\/auth\?code=\w+(&[\w_-]+=[\w_-]+)*/;
  const serialCode = to.query.code;

  if (
    !serialCode ||
    serialCode === String(0) ||
    !regex.test(window.location.href)
  ) {
    return await router.push({
      name: 'campaign.error.404',
      replace: true
    });
  }

  // [API] play and return lottery result
  const result = await campaignService.lotteryPlayRedirect({
    campaign_url_name: campaignUrlName,
    campaign_url: campaignUrl,
    serial_code: serialCode,
    utm_campaign: to.query?.utm_campaign ? String (to.query.utm_campaign) : '',
    utm_medium: to.query?.utm_medium ? String (to.query.utm_medium) : '',
    utm_source: to.query?.utm_source ? String (to.query.utm_source) : '',
  });

  if (result && result?.status && result?.data) {
    let data = result.data;

    // Return error page if fetch fail
    if (result.status !== HTTP_CODE_OK) {
      return await router.push({
        name: 'campaign.error',
        params: {
          IpBlock: data?.status === API_STATUS_CODE_RATE_LIMIT_EXCEED,
        },
        replace: true
      });
    }

    // Process lottery result
    switch (data?.win_flag) {
      case WIN_FLAG:
        var giftUrl = data?.campaign_gift?.gift_url;

        // Not have gift_url -> redirect to error page
        if (!giftUrl) {
          return await router.push({
            name: 'campaign.error',
            replace: true
          });
        }

        // Redirect to gift_url
        return window.location.href = giftUrl;
      case LOSE_FLAG:
        // Set lottery uuid
        var lotteryUuid = data?.lottery_url;
        // Set lottery campaign win flag
        store.dispatch('setLotteryCampaignWinFlg', LOSE_FLAG);

        // Redirect to lose page
        return await router.push({
          name: 'lottery.game',
          params: { campaignUrlName, lotteryUuid }
        }).catch(() => {});
    }
  }

  // If something was wrong not redirect in above -> direct to error page
  return await router.push({
    name: 'campaign.error',
    replace: true
  });
};

export default async function checkCampaign({ to, next, router }) {
  try {
    const campaignUrlName = String(to.params.campaignUrlName);
    const campaignUrl = String(to.params.campaignUrl);

    // Set campaign url and campaign url name
    store.dispatch('setCampaignUrlData', {
      campaignUrl,
      campaignUrlName,
    });

    // [API] fetch campaign setting
    const result = await store.dispatch('loadCampaign', {
      campaignUrlName,
      campaignUrl,
    });

    if (result && result?.status && result?.data) {
      let data = result.data;

      // Status is not 200 -> show error
      if (result.status !== HTTP_CODE_OK) {
        return await router.push({
          name: 'campaign.error.404',
          replace: true
        });
      }

      // Set language for app base on campaign's language
      router.app._i18n.locale = LANGUAGES[data?.country?.language_id ?? APP_LANG_DEFAULT];

      // Process campaign by flow
      const campaignInfo = data?.campaign_info;

      // Check campaign type must be CAMPAIGN_SERIAL_TYPE || CAMPAIGN_PHONE_NUMBER_TYPE
      if (![CAMPAIGN_SERIAL_TYPE, CAMPAIGN_PHONE_NUMBER_TYPE].includes(campaignInfo?.campaign_type)) {
        return await router.push({
          name: 'campaign.error.404',
          replace: true
        });
      }

      if (campaignInfo?.campaign_flow == FLOW_TYPE_REDIRECT) {
        // Start page loading
        store.dispatch('startPageRedirectLoading');

        return processCampaignFlowTypeRedirect(
          {
            to,
            router,
          },
          {
            campaignUrl,
            campaignUrlName,
            campaignInfo,
          }
        );
      }

      // Campaign is redemption campaign
      if (campaignInfo?.is_redemption_campaign && to.name !== ROUTER_MAPS[FLOW_TYPE_REDEMPTION]) {
        let newQuery = {};

        // Check if have utm will append to query
        const { utm_campaign, utm_medium, utm_source } = store.getters.utmData;
        if (utm_campaign) newQuery.utm_campaign = utm_campaign;
        if (utm_medium) newQuery.utm_medium = utm_medium;
        if (utm_source) newQuery.utm_source = utm_source;

        return await router.push({
          name: ROUTER_MAPS[FLOW_TYPE_REDEMPTION],
          params: {
            campaignUrlName: store.state.campaignUrlName,
            campaignUrl: store.state.campaignUrl,
          },
          query: newQuery,
          replace: true,
        });
      }
    }

    // Campaign flow is None | Survey
    return await next();
  } catch (error) {
    return await router.push({
      name: 'campaign.error',
      replace: true
    });
  }
}
