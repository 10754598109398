export default {
  name: 'MainVisualContent',
  props: {
    mainVisualImageUrl: String,
    defaultImageUrl: String,
    mainText: String,
    mainSubText: String,
    itemBrandName: String,
    itemName: String,
  },
  computed: {
    isDefaultImage() {
        return this.mainVisualImageUrl == this.defaultImageUrl;
    }
  }
};
